
export default {
    name: "BusinessNameEdit",
    props: {
        givenBusiness: {
            type: Object,
            default: null,
        },
    },
}
